/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PaymentForm from '../../modules/PaymentForm';
import Form from '../../modules/Form';
import {
  TEMPLATE_DONATION_FORM as HARISUMIRAN_DONATE_FORM_CONFIG,
  TX_DONATION_FORM
} from '../../templates/DONATION_FORM';
import { TEMPLATE_DONATION_FORM as ACC_DONATE_FORM_CONFIG } from '../../templates/ACC_DONATION_FORM';
import { emptyMember } from '../../util/member';
import { donate } from '../../apis/donation';
import { showError, saveTransactionData } from '../../reducers/flowReducer';
import { WebsiteType } from '../../constants/websiteTypes';
import { Loader } from '../../components/Loader';

import styles from './styles.module.scss';
import { getStripeAPIKeyByLocation } from '../../util/getStripeAPIKeyByLocation';
import CENTERS from '../../constants/centers';

const DONATE_FORM_TYPE = process.env.REACT_APP_DONATE_FORM_TYPE;

let donateFormConfig = HARISUMIRAN_DONATE_FORM_CONFIG;
if (DONATE_FORM_TYPE === WebsiteType.ACC) {
  donateFormConfig = ACC_DONATE_FORM_CONFIG as any;
}

const _DonationForm = ({ submitPage, errorString, reportError, saveTransactionInfo }) => {
  const mappedInitialValues = emptyMember;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { center } = useParams<{ center: string }>();
  let apiKey = '';

  if (center?.toUpperCase() === 'TX') {
    donateFormConfig = TX_DONATION_FORM;
    mappedInitialValues.center = CENTERS[1].value;
  }
  if (center?.toUpperCase() === 'IL') {
    const ILConfig = {
      ...HARISUMIRAN_DONATE_FORM_CONFIG,
      fields: HARISUMIRAN_DONATE_FORM_CONFIG.fields.map((f) => {
        if (f.name === 'center') {
          f.default = CENTERS[2].value;
          mappedInitialValues.center = CENTERS[2].value;
        }
        return f;
      })
    };
    donateFormConfig = ILConfig;
  }
  if (DONATE_FORM_TYPE === WebsiteType.HARISUMIRAN) {
    apiKey = getStripeAPIKeyByLocation(center?.toLocaleUpperCase() ?? 'NJ');
  } else if (DONATE_FORM_TYPE === WebsiteType.ACC) {
    apiKey = process.env.REACT_APP_STRIPE_API_KEY || '';
  }

  const onSubmit = async ({ data, stripeToken, captchaToken }) =>
    new Promise<void>(async (resolve, reject) => {
      setIsLoading(true);
      // Set sevaFor value to 'ACC-DONATION' if using ACC template
      let sevaForValue = data.sevaFor;
      let centerData = 'NJ';
      if (DONATE_FORM_TYPE === WebsiteType.ACC) {
        sevaForValue = 'ACC-DONATION';
      } else {
        centerData = center.toUpperCase();
      }

      submitPage({
        ...data,
        center: centerData,
        sevaFor: sevaForValue,
        cardToken: { id: stripeToken.token.id },
        reCaptchaToken: captchaToken
      })
        .then((data) => {
          resolve();
          saveTransactionInfo(data.message);
          setIsLoading(false);
          history.push('/thank-you');
        })
        .catch((err) => {
          reportError(err.message);
          setIsLoading(false);
        });
    });

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Form
        config={donateFormConfig}
        initialValues={mappedInitialValues}
        onSubmit={onSubmit}
        renderComponent={({ onSubmit }) => (
          <>
            <PaymentForm
              center={center?.toLocaleUpperCase()}
              config={donateFormConfig}
              errorString={errorString}
              apiKey={apiKey}
              onTokenReady={() => {}}
              onSubmit={onSubmit}
            />
          </>
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  errorString: state.flow.formError
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  reportError: (message) => {
    dispatch(showError(message));
  },
  submitPage: (payload) => {
    const { addressDetails, ...data } = payload;
    const { address1, address2 } = addressDetails;
    const request = {
      ...data,
      address: [address1, address2].filter(Boolean).join(' '),
      city: addressDetails.city,
      state: addressDetails.state,
      country: addressDetails.country,
      zip: addressDetails.zip
    };
    dispatch(showError(null));
    return donate(request);
  },
  saveTransactionInfo: (payload) => {
    dispatch(saveTransactionData(payload));
  }
});

export const DonationForm = connect(mapStateToProps, mapDispatchToProps)(_DonationForm);
