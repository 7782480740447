import React from 'react';
import './App.css';
import { DonationForm } from './containers/donationForm';
import { ThankYou } from './containers/thank-you';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import HARISUMIRAN_HERO from './assets/images/hero.png';
import HOUSTON_DONATION_HERO from './assets/images/houston-donation.jpg';
import CHICAGO_DONATION_HERO from './assets/images/chicago-donation.jpg';
import HOUSTON_DONATION_HERO_MOBILE from './assets/images/houston-donation-mobile.jpg';

import ACC_HERO from './assets/images/acc_donate_banner.png';
import { WebsiteType } from './constants/websiteTypes';
import { PageHeader } from './components/PageHeader/PageHeader';

const DONATE_FORM_TYPE = process.env.REACT_APP_DONATE_FORM_TYPE;

let donationBackgroundImg = HARISUMIRAN_HERO;
let heroAltText = 'harisumiran mandir';
let donationPath = '/:center';
let heroImgClass = 'hero-img';
let donationBackgroundImgMobile = '';

if (DONATE_FORM_TYPE === WebsiteType.ACC) {
  donationBackgroundImg = ACC_HERO;
  heroAltText = 'acc hero image';
  donationPath = '/';
}

const App: React.FC = () => {
  const isTXPage = window.location.pathname.toUpperCase() === '/TX';
  const isNJPage = window.location.pathname.toUpperCase() === '/NJ';
  if (isTXPage) {
    donationBackgroundImg = HOUSTON_DONATION_HERO;
    donationBackgroundImgMobile = HOUSTON_DONATION_HERO_MOBILE;
    heroImgClass = 'hero-img-tx';
    heroAltText = 'harisumiran mandir houston';
  }
  if (window.location.pathname.toUpperCase() === '/IL') {
    donationBackgroundImg = CHICAGO_DONATION_HERO;
    heroImgClass = 'hero-img-tx';
    heroAltText = 'harisumiran mandir chicago';
  }

  const showHarisumiranHeader = DONATE_FORM_TYPE !== WebsiteType.ACC && isNJPage;
  return (
    <div className={'main'}>
      <PageHeader showHarisumiranHeader={showHarisumiranHeader} />
      {isTXPage ? (
        <picture>
          <source
            media="(min-width: 900px)"
            srcSet={`${donationBackgroundImg} 1x, ${donationBackgroundImg} 2x`}
            type="image/webp"
          />
          <source
            media="(max-width: 600px)"
            srcSet={`${donationBackgroundImgMobile} 1x, ${donationBackgroundImgMobile} 2x`}
            type="image/webp"
          />
          <img className={heroImgClass} src={donationBackgroundImgMobile} alt={heroAltText} />
        </picture>
      ) : (
        <img className={heroImgClass} src={donationBackgroundImg} alt={heroAltText} />
      )}

      <div className="app-container">
        <Router>
          <Switch>
            <Route path="/thank-you">
              <ThankYou />
            </Route>

            <Route path={donationPath}>
              <DonationForm />
            </Route>

            <Route path="*">
              <Redirect to="/NJ" />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default App;
